import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './type';
import { saveMatchSettings } from '../utils/db';
import { sendMessage, isChannelAvailable } from '../utils/broadcast';

interface MatchState {
  court: string;
  matchNumber: number;
  category: string;
}

const initialState: MatchState = {
  court: 'A',
  matchNumber: 1,
  category: '幼年男女混合初心',
};

export const matchSlice = createSlice({
  name: 'match',
  initialState,
  reducers: {
    updateMatchInfo: (state, action: PayloadAction<MatchState>) => {
      state.court = action.payload.court;
      state.matchNumber = action.payload.matchNumber;
      state.category = action.payload.category;
    }
  }
});

export const { updateMatchInfo } = matchSlice.actions;

export const updateMatchInfoAsync = (matchInfo: MatchState): AppThunk => async (dispatch, getState) => {
  try {
    // Redux store更新
    dispatch(updateMatchInfo(matchInfo));

    // IndexedDBに保存
    await saveMatchSettings(matchInfo);

    if (isChannelAvailable()) {
      // メッセージを送信
      sendMessage('matchSettingsUpdated', matchInfo);
    }

    // 現在の状態を取得
    const state = getState();
    const players = state.players.playerList;

    // API送信用のデータを準備
    const apiData = {
      court: matchInfo.court,
      match_number: matchInfo.matchNumber.toString(),
      player1: {
        name: players[0].name,
        number: players[0].number,
      },
      player2: {
        name: players[1].name,
        number: players[1].number,
      },
    };

    // APIエンドポイント
    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://realtime.matchsphere.jp/api/karate-match'
      : 'https://realtime.matchsphere.jp/api/karate-match';

    // POST to API
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiData),
    });

    if (!response.ok) {
      throw new Error('Failed to update match info on server');
    }

    // レスポンスの処理（必要に応じて）
    const responseData = await response.json();
    // console.log('Server response:', responseData);

  } catch (error) {
    console.error('Error updating match info:', error);
    // エラー処理（例：ユーザーへの通知など）
  }
};

export default matchSlice.reducer;
