import { configureStore } from '@reduxjs/toolkit';
import playerReducer from './playerSlice';
import matchReducer from './matchSlice';
import timerReducer from './timerSlice';
import pahseReducer from './phaseSlice';

export const store = configureStore({
  reducer: {
    players: playerReducer,
    match: matchReducer,
    timer: timerReducer,
    phase: pahseReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
