import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updatePlayer } from '../store/playerSlice';
import { getPlayerByNumber } from '../utils/playerUtils';
import { savePlayer } from '../utils/db';
import { sendMessage, isChannelAvailable } from '../utils/broadcast';

const PlayerInput = ({ playerId, index }) => {
  const dispatch = useDispatch();
  const player = useSelector(state => state.players.playerList.find(p => p.id === playerId));

  useEffect(() => {
    if (player.number) {
      const info = getPlayerByNumber(player.number);
      handlePlayerUpdate({ ...player, ...info });
    }
  }, [player.number]);

  const handleNumberChange = (value) => {
    handlePlayerUpdate({ ...player, number: value });
  };

  const handleColorChange = (color) => {
    handlePlayerUpdate({ ...player, color });
  };

  const handlePlayerUpdate = async (updatedPlayer) => {
    try {
      // IndexedDBにプレイヤー情報を保存
      await savePlayer(updatedPlayer);

      // Redux storeを更新
      dispatch(updatePlayer(updatedPlayer));

      if (isChannelAvailable()) {
        // プレイヤー情報更新通知送信
        sendMessage('playerUpdated', { index, player: updatedPlayer });
      }
    } catch (error) {
      console.error('プレイヤー更新中にエラーが発生しました:', error);
    }
  };

  return (
    <div className="p-playerInput">
      <h2 className="p-playerInput__mainTitle">選手{index + 1}情報</h2>
      <div className="p-playerInput-number">
        <h3 className="p-playerInput-number__title">ゼッケン番号入力</h3>

        <input
          type="number"
          placeholder="ゼッケン番号"
          value={player.number}
          onChange={(e) => handleNumberChange(e.target.value)}
          className="p-playerInput-number__input"
        />

        <div className="p-playerInput-zekken">
          <button
            onClick={() => handleColorChange('red')}
            className={`p-playerInput-zekken__color p-playerInput-zekken__color--red ${player.color === 'red' ? 'p-playerInput-zekken--selectetd' : ''}`}
          >
            赤
          </button>
          <button
            onClick={() => handleColorChange('white')}
            className={`p-playerInput-zekken__color p-playerInput-zekken__color--white ${player.color === 'white' ? 'p-playerInput-zekken--selectetd' : ''}`}
          >
            白
          </button>
        </div>
      </div>

      <div className="p-playerInput-info">
        <span>名前: {player.name}</span>
        <span>道場名: {player.dojo}</span>
      </div>
    </div>
  );
};

export default PlayerInput;
