import React from 'react';
import { useSelector } from 'react-redux';

const MatchNumber = React.memo(() => {
  const matchInfo = useSelector((state) => state.match);

  return (
    <div className="p-match-info">
      <div className="p-match-court">
        <span className="p-match-court__value">{matchInfo.court}</span>
      </div>
      <div className="p-match-number">
        <span className="p-match-label">MATCH</span>
        <span className="p-match-value">{matchInfo.matchNumber}</span>
      </div>
    </div>
  );
});

export default MatchNumber;
