import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { saveTimerSettings } from '../utils/db';

// タイマーの状態を表す型
export type TimerState = 'initial' | 'running' | 'paused' | 'finished';

// タイマーのステート型定義
interface TimerSliceState {
  initialTime: number;     // 初期設定時間（秒）
  currentTime: number;     // 現在の残り時間（秒）
  startTime: number | null; // タイマー開始時刻
  pausedTime: number | null; // タイマー一時停止時刻
  totalPausedTime: number; // 合計一時停止時間
  timerState: TimerState;  // タイマーの状態
}

// 初期状態
const initialState: TimerSliceState = {
  initialTime: 60,  // デフォルトは3分
  currentTime: 60,
  startTime: null,
  pausedTime: null,
  totalPausedTime: 0,
  timerState: 'initial',
};

// タイマーのスライス定義
export const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    // タイマーを開始
    startTimer: (state) => {
      state.startTime = Date.now();
      state.timerState = 'running';
      saveTimerSettings(state);
    },
    // タイマーを一時停止
    pauseTimer: (state) => {
      state.pausedTime = Date.now();
      state.timerState = 'paused';
      saveTimerSettings(state);
    },
    // タイマーを再開
    resumeTimer: (state) => {
      if (state.pausedTime) {
        state.totalPausedTime += Date.now() - state.pausedTime;
        state.pausedTime = null;
      }
      state.timerState = 'running';
      saveTimerSettings(state);
    },
    // タイマーを停止
    stopTimer: (state) => {
      state.timerState = 'finished';
      saveTimerSettings(state);
    },
    // タイマーをリセット
    resetTimer: (state, action: PayloadAction<number>) => {
      state.initialTime = action.payload;
      state.currentTime = action.payload;
      state.startTime = null;
      state.pausedTime = null;
      state.totalPausedTime = 0;
      state.timerState = 'initial';
      saveTimerSettings(state);
    },
    // 現在の時間を更新
    updateCurrentTime: (state, action: PayloadAction<number>) => {
      state.currentTime = action.payload;
    },
  },
});

// アクションクリエーターをエクスポート
export const {
  startTimer,
  pauseTimer,
  resumeTimer,
  stopTimer,
  resetTimer,
  updateCurrentTime,
} = timerSlice.actions;

export default timerSlice.reducer;
