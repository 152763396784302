import Dexie from 'dexie';

// データベースの初期化
const db = new Dexie('KarateTournamentDB');

// データベースのバージョンとスキーマの定義
db.version(3).stores({
  players: '++id, name, number, color, wazaari, chui, genten',
  matchSettings: 'id, court, matchNumber, matchPhase, startTime, timerState, initialTime, elapsedTime, totalPausedTime',
  timerSettings: 'id, time, isRunning, startTime, pausedTime, totalPausedTime, timerState'
});

export const savePlayer = async (player) => {
  try {
    await db.players.put(player);
  } catch (error) {
    console.error('Error saving player:', error);
    throw error;
  }
};

export const getPlayers = async () => {
  try {
    return await db.players.toArray();
  } catch (error) {
    console.error('Error getting players:', error);
    throw error;
  }
};

export const saveMatchSettings = async (settings) => {
  try {
    await db.matchSettings.put({ id: 1, ...settings });
  } catch (error) {
    console.error('Error saving match settings:', error);
    throw error;
  }
};

export const getMatchSettings = async () => {
  try {
    return await db.matchSettings.get(1);
  } catch (error) {
    console.error('Error getting match settings:', error);
    throw error;
  }
};

// タイマー設定の保存
export const saveTimerSettings = async (settings) => {
  try {
    await db.timerSettings.put({ id: 1, ...settings });
  } catch (error) {
    console.error('タイマー設定の保存中にエラーが発生しました:', error);
    throw error;
  }
};

// タイマー設定の取得
export const getTimerSettings = async () => {
  try {
    return await db.timerSettings.get(1);
  } catch (error) {
    console.error('タイマー設定の取得中にエラーが発生しました:', error);
    throw error;
  }
};

export default db;
