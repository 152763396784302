import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

// メモ化されたセレクタを作成
const makeSelectPlayerStats = () => createSelector(
  (state) => state.players.playerList,
  (_, id) => id,
  (playerList, id) => {
    const player = playerList.find(p => p.id === id);
    return player
      ? { wazaari: player.wazaari, chui: player.chui, genten: player.genten }
      : { wazaari: 0, chui: 0, genten: 0 };
  }
);

const ScoreBoard = React.memo(({ id, addClass }) => {
  // コンポーネントごとにセレクタのインスタンスを作成
  const selectPlayerStats = useMemo(makeSelectPlayerStats, []);

  // メモ化されたセレクタを使用
  const playerStats = useSelector(state => selectPlayerStats(state, id));

  return (
    <div className={`p-player-score ${addClass}`}>
      <div className="p-player-score__item">
        <span className="p-player-score__label">技有</span>
        <span className="p-player-score__value">{playerStats.wazaari}</span>
      </div>
      {/* <div className="p-player-score__item">
        <span className="p-player-score__label">減</span>
        <span className="p-player-score__value">{playerStats.genten}</span>
      </div> */}
      <div className="p-player-score__item">
        <span className="p-player-score__label">注意</span>
        <span className="p-player-score__value">{playerStats.chui}</span>
      </div>
    </div>
  );
});

export default ScoreBoard;
