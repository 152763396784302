let channel;

try {
  channel = new BroadcastChannel('karate_tournament_channel');
} catch (error) {
  console.warn('BroadcastChannel is not supported in this browser. Some features may not work.', error);
}

export const sendMessage = (type, data) => {
  if (channel) {
    try {
      channel.postMessage({ type, data });
    } catch (error) {
      console.error('Error sending message:', error);
    }
  } else {
    console.warn('Unable to send message: BroadcastChannel not available');
  }
};

export const subscribeToMessages = (callback) => {
  if (channel) {
    const eventListener = (event) => {
      try {
        callback(event.data);
      } catch (error) {
        console.error('Error processing message:', error);
      }
    };
    channel.addEventListener('message', eventListener);

    return () => {
      channel.removeEventListener('message', eventListener);
    };
  } else {
    console.warn('Unable to subscribe to messages: BroadcastChannel not available');
    return () => {}; // ダミーのクリーンアップ関数
  }
};

export const isChannelAvailable = () => !!channel;
