import { configureStore } from '@reduxjs/toolkit';
import { generalDisplayPlayerSlice } from './generalPlayerSlice';
import { generalDisplayMatchSlice } from './generalMatchSlice';
import { generalDisplayTimerSlice } from './generalTimerSlice';

export const generalDisplayStore = configureStore({
  reducer: {
    players: generalDisplayPlayerSlice.reducer,
    match: generalDisplayMatchSlice.reducer,
    timer: generalDisplayTimerSlice.reducer,
  },
});

export type GeneralDisplayRootState = ReturnType<typeof generalDisplayStore.getState>;
export type GeneralDisplayAppDispatch = typeof generalDisplayStore.dispatch;
