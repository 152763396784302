import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updatePlayer } from '../store/playerSlice';
import PlayerInput from '../components/PlayerInput';
import TimerControl from '../components/TimerControl';
import ScoreControl from '../components/ScoreControl';
import MatchInput from '../components/MatchInput';
import { getPlayers, getMatchSettings } from '../utils/db';

const AdminPanel = () => {
  const players = useSelector(state => state.players.playerList);
  const match = useSelector(state => state.match);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async () => {
      try {
        const savedPlayers = await getPlayers();
        if (savedPlayers.length >= 2) {
          savedPlayers.forEach(player => dispatch(updatePlayer(player)));
        }

        const savedSettings = await getMatchSettings();
        if (savedSettings) {
          // matchSliceのアクションを使用して試合設定を更新
          // 注意: このアクションはまだ定義されていないので、matchSliceに追加する必要があります
          // dispatch(updateMatchSettings(savedSettings));
        }
      } catch (error) {
        console.error('データ取得中にエラーが発生しました:', error);
      }
    };

    loadData();
  }, [dispatch]);

  return (
    <div className='p-admin'>
      <nav className="p-admin-nav">
        <ul>
          <li><Link to="/timer">大会表示画面</Link></li>
          <li><Link to="/">管理画面</Link></li>
        </ul>
      </nav>

      <div className="p-admin-players">
        <div className="p-admin-player">
          <PlayerInput
            playerId={0}
            index={0}
          />
          <ScoreControl playerId={0} />
        </div>

        <div className="p-admin-player">
          <PlayerInput
            playerId={1}
            index={1}
          />
          <ScoreControl playerId={1} />
        </div>
      </div>


      <div className="p-admin-time">
        <MatchInput
          initialCourt={match.court}
          initialMatchNumber={match.matchNumber}
          initialCategory={match.category}
        />
        <TimerControl />
      </div>
      <div>

      <div className="p-admin-iframe">
      <iframe class="p-admin-iframe__child" src="https://timer.matchsphere.jp/timer" width="1920px" height="1080px" title="プレビュー" />

      </div>

      </div>

      <div className="p-admin-reference">
        <b>タイマーの操作方法</b><br />
        1. 両選手のゼッケン番号を入力してください。<br />
        2. コート名、試合番号を設定してください。<br />
        3. 「QR送信設定」をクリックして試合状況を送信してください。<br />
        4. 時間を設定して「スタート」をクリックしてください。<br />
        5. 試合が終了したら「リセット」をクリックしてください。<br />
        <em>リセットボタンを押すときは必ず2回クリックしてください</em>
      </div>

    </div>
  );
};

export default AdminPanel;
