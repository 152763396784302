import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { generalDisplayStore } from './store/general';
import GeneralDisplay from './pages/GeneralDisplay';
import AdminPanel from './pages/AdminPanel';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <div className="p-main">
          <Routes>
            <Route
              path="/timer"
              element={
                <Provider store={generalDisplayStore}>
                  <GeneralDisplay />
                </Provider>
              }
            />
            <Route path="/" element={<AdminPanel />} />
          </Routes>
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
