import React from 'react';
import { useSelector } from 'react-redux';
import { GeneralDisplayRootState } from '../store/general'; // パスは実際の構造に合わせて調整してください

const PlayerNumber = React.memo(({ id, addClass = '' }) => {
  const playerNumber = useSelector((state: GeneralDisplayRootState) => {
    const player = state.players.playerList.find(p => p.id === id);
    return player ? player.number : '';
  });

  return (
    <div className={`p-player-number ${addClass}`}>
      <span className="p-player-number__text">No.</span>
      <span className="p-player-number__number">{playerNumber}</span>
    </div>
  );
});

export default PlayerNumber;
