import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateWazaari, updateChui, updateGenten } from '../store/playerSlice';
import { savePlayer } from '../utils/db';
import { sendMessage, isChannelAvailable } from '../utils/broadcast';

const ScoreControl = ({ playerId }) => {
  const dispatch = useDispatch();
  const player = useSelector(state =>
    state.players.playerList.find(p => p.id === playerId)
  );

  const handleChange = async (type, change) => {
    if (!player) return;

    let newValue;
    let action;
    let updatedPlayer;

    switch (type) {
      case 'wazaari':
        newValue = Math.min(Math.max(0, player.wazaari + change), 2);
        action = updateWazaari({ id: playerId, wazaari: newValue });

        dispatch(action);
        updatedPlayer = { ...player, [type]: newValue };
        break;

      case 'chui':
        newValue = Math.min(Math.max(0, player.chui + change), 6);

        if (newValue === 2 && false) {
          // 注意を０にリセット
          const chuiAction = updateChui({ id: playerId, chui: 0 });
          dispatch(chuiAction);

          // 減点を１加算
          const gentenValue = Math.min(Math.max(0, player.genten + 1));
          const gentenAction = updateGenten({ id: playerId, genten: gentenValue });
          dispatch(gentenAction);

          updatedPlayer = { ...player, 'chui': 0, genten: gentenValue };
        } else {
          // 通常通り1追加
          action = updateChui({ id: playerId, chui: newValue });
          dispatch(action);
          updatedPlayer = { ...player, [type]: newValue };
        }
        break;

      case 'genten':
        newValue = Math.min(Math.max(0, player.genten + change), 2);
        action = updateGenten({ id: playerId, genten: newValue });
        dispatch(action);
        updatedPlayer = { ...player, [type]: newValue };
        break;

      case 'reset':
        dispatch(
          updateWazaari(
            { id: playerId, wazaari: 0 }
          )
        );
        dispatch(
          updateChui(
            { id: playerId, chui: 0 }
          )
        );
        dispatch(
          updateGenten(
            { id: playerId, genten: 0 }
          )
        );

        updatedPlayer = { ...player, 'wazaari': 0, 'chui': 0, 'genten': 0 };
        break;

      default:
        return;
    }

    try {
      await savePlayer(updatedPlayer);
      if (isChannelAvailable()) {
        sendMessage('playerUpdated', { player: updatedPlayer });
      }
    } catch (error) {
      console.error('プレイヤー更新中にエラーが発生しました:', error);
    }
  };

  if (!player) return null;

  return (
    <div className="p-scoreInput">
      <div className="p-scoreInput__wrap">
        <div className="p-scoreInput__box">
          <span className="p-scoreInput__title">技あり</span>
          <div className="p-scoreInput-setting">
            <span className="p-scoreInput__number">{player.wazaari}</span>
            <div className="p-scoreInput-buttons">
              <button className="p-scoreInput-button__plus" onClick={() => handleChange('wazaari', 1)}>▲</button>
              <button className="p-scoreInput-button__minus" onClick={() => handleChange('wazaari', -1)}>▼</button>
            </div>
          </div>
        </div>
        {/* <div className="p-scoreInput__box">
          <span className="p-scoreInput__title">減点</span>
          <div className="p-scoreInput-setting">
            <span className="p-scoreInput__number">{player.genten}</span>
            <div className="p-scoreInput-buttons">
              <button className="p-scoreInput-button__plus" onClick={() => handleChange('genten', 1)}>▲</button>
            <button className="p-scoreInput-button__minus" onClick={() => handleChange('genten', -1)}>▼</button>
            </div>
          </div>
        </div> */}
        <div className="p-scoreInput__box">
          <span className="p-scoreInput__title">注意</span>
          <div className="p-scoreInput-setting">
            <span className="p-scoreInput__number">{player.chui}</span>
            <div className="p-scoreInput-buttons">
              <button className="p-scoreInput-button__plus" onClick={() => handleChange('chui', 1)}>▲</button>
              <button className="p-scoreInput-button__minus" onClick={() => handleChange('chui', -1)}>▼</button>
            </div>
          </div>
        </div>
      </div>

      <div className="p-scoreInput-reset">
        <button className="p-scoreInput-reset__button" onClick={() => handleChange('reset', 0)}>スコアリセット</button>
      </div>
    </div>
  );
};

export default ScoreControl;
