import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { GeneralDisplayAppDispatch, GeneralDisplayRootState } from '../store/general';
import { updatePlayer } from '../store/generalPlayerSlice';
import { updateMatchInfo } from '../store/generalMatchSlice';
import { updateTimer, updateMatchPhase } from '../store/generalTimerSlice';
import { getPlayers, getMatchSettings } from '../utils/db';
import { subscribeToMessages, isChannelAvailable } from '../utils/broadcast';
import PlayerName from '../components/PlayerName';
import PlayerNumber from '../components/PlayerNumber';
import PlayerInfo from '../components/PlayerInfo';
import Timer from '../components/Timer';
import ScoreBoard from '../components/ScoreBoard';
import MatchNumber from '../components/MatchNumber';
import MatchCategory from '../components/MatchCategory';

// プレイヤーの color を取得するセレクタを作成
const makeSelectPlayerColor = () => createSelector(
  (state: GeneralDisplayRootState) => state.players.playerList,
  (_, id) => id,
  (playerList, id) => {
    const player = playerList.find(p => p.id === id);
    return player ? player.color : '';
  }
);

const GeneralDisplay: React.FC = () => {
  const dispatch = useDispatch<GeneralDisplayAppDispatch>();

  // 各プレイヤーの color を取得するためのセレクタを作成
  const selectPlayer0Color = React.useMemo(() => makeSelectPlayerColor(), []);
  const selectPlayer1Color = React.useMemo(() => makeSelectPlayerColor(), []);

  // セレクタを使用して color を取得
  const player0Color = useSelector((state: GeneralDisplayRootState) => selectPlayer0Color(state, 0));
  const player1Color = useSelector((state: GeneralDisplayRootState) => selectPlayer1Color(state, 1));


  useEffect(() => {
    const loadData = async () => {
      try {
        const savedPlayers = await getPlayers();
        savedPlayers.forEach(player => dispatch(updatePlayer(player)));

        const savedSettings = await getMatchSettings();
        if (savedSettings) {
          dispatch(updateMatchInfo({
            court: savedSettings.court,
            matchNumber: savedSettings.matchNumber,
            matchPhase: savedSettings.matchPhase,
          }));
          dispatch(updateTimer({
            time: savedSettings.time,
            isRunning: savedSettings.isRunning,
          }));
        }
      } catch (error) {
        console.error('データ取得中にエラーが発生しました:', error);
      }
    };

    loadData();

    if (isChannelAvailable()) {
      const unsubscribe = subscribeToMessages((message) => {
        if (message.type === 'playerUpdated') {
          dispatch(updatePlayer(message.data.player));
        } else if (message.type === 'matchSettingsUpdated') {
          dispatch(updateMatchInfo(message.data));
        } else if (message.type === 'updateTimer') {
          dispatch(updateTimer(message.data));
          // console.log("timer", message.data)
        } else if (message.type === 'updateMatchPhase') {
          dispatch(updateMatchPhase(message.data.matchPhase));
          // console.log("matchPhase", message.data.matchPhase);
        }
      });

      return () => unsubscribe();
    }
  }, [dispatch]);

  return (
    <div className="p-layout">
      <div className="p-layout-info">
        <div className="p-layout-info__score">
          <ScoreBoard id={0} addClass={`p-player-score--left p-player-score--${player0Color}`}/>
        </div>

        <div className="p-layout-info__category"><MatchCategory /></div>

        <div className="p-layout-info__score">
          <ScoreBoard id={1} addClass={`p-player-score--right p-player-score--${player1Color}`}/>
        </div>
      </div>

      <div className="p-layout-time">
        <PlayerNumber id={0} addClass={`p-player-number--left p-player-number--${player0Color}`} />
        <Timer />
        <PlayerNumber id={1} addClass={`p-player-number--right p-player-number--${player1Color}`}/>
      </div>

      <div className="p-layout-players">
        <div className="p-layout-bg">
          <div className={`p-layout-bg__item p-layout-bg__item--${player0Color}`}></div>
          <div className={`p-layout-bg__item p-layout-bg__item--${player1Color}`}></div>
        </div>

        <div className="p-layout-players__item">
          <PlayerName id={0} addClass={`p-player-name--${player0Color}`}/>
        </div>

        <div className="p-layout-players__match">
          <MatchNumber />
        </div>

        <div className="p-layout-players__item">
          <PlayerName id={1} addClass={`p-player-name--${player1Color}`}/>
        </div>
      </div>
    </div>
  );
};

export default GeneralDisplay;
