import React from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
const MatchCategory = React.memo(() => {
  const matchInfo = useSelector((state) => state.match);
  const createMarkup = (html) => {
    return {__html: DOMPurify.sanitize(html)};
  }
  return (
    <div className="p-match-category">
 <div dangerouslySetInnerHTML={createMarkup(matchInfo.category)} />

    </div>
  );
});

export default MatchCategory;
