import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Player {
  id: number;
  name: string;
  number: string;
  wazaari: number;
  genten: number;
  chui: number;
  color: 'red' | 'white';
}

interface PlayerState {
  playerList: Player[];
}

const initialState: PlayerState = {
  playerList: [
    { id: 0, name: '', number: '1', wazaari: 0, genten: 0, chui: 0, color: 'red' },
    { id: 1, name: '', number: '1', wazaari: 0, genten: 0, chui: 0, color: 'white' }
  ]
};

export const playerSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    updatePlayer: (state, action: PayloadAction<Player>) => {
      const index = state.playerList.findIndex(p => p.id === action.payload.id);
      if (index !== -1) {
        state.playerList[index] = action.payload;
      }
    },
    updateWazaari: (state, action: PayloadAction<{ id: number, wazaari: number }>) => {
      const player = state.playerList.find(p => p.id === action.payload.id);
      if (player) {
        player.wazaari = action.payload.wazaari;
      }
    },
    updateGenten: (state, action: PayloadAction<{ id: number, genten: number }>) => {
      const player = state.playerList.find(p => p.id === action.payload.id);
      if (player) {
        player.genten = action.payload.genten;
      }
    },
    updateChui: (state, action: PayloadAction<{ id: number, chui: number }>) => {
      const player = state.playerList.find(p => p.id === action.payload.id);
      if (player) {
        player.chui = action.payload.chui;
      }
    },
  }
});

export const { updatePlayer, updateWazaari, updateGenten, updateChui } = playerSlice.actions;

export default playerSlice.reducer;
