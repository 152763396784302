import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TimerSliceState {
  currentTime: number;
  timerState: 'initial' | 'running' | 'paused' | 'finished';
  matchPhase: 'main' | 'extension' | 'reextension';
}

const initialState: TimerSliceState = {
  currentTime: 60,
  timerState: 'initial',
  matchPhase: 'main',
};

export const generalDisplayTimerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    updateTimer: (state, action: PayloadAction<Partial<TimerSliceState>>) => {
      return { ...state, ...action.payload };
    },
    updateMatchPhase: (state, action: PayloadAction<'main' | 'extension' | 'reextension'>) => {
      state.matchPhase = action.payload;
    },
  },
});

export const { updateTimer, updateMatchPhase  } = generalDisplayTimerSlice.actions;
export default generalDisplayTimerSlice.reducer;
