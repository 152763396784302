import React from 'react';
import { useSelector } from 'react-redux';
import { GeneralDisplayRootState } from '../store/general'; // パスは実際の構造に合わせて調整してください

const PlayerName = React.memo(({ id, addClass = '' }) => {
  const playerName = useSelector((state: GeneralDisplayRootState) => {
    const player = state.players.playerList.find(p => p.id === id);
    return player ? player.name : '';
  });

  return (
    <div className={`p-player-name ${addClass}`}>{playerName}</div>
  );
});

export default PlayerName;
