import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  startTimer,
  pauseTimer,
  resumeTimer,
  stopTimer,
  resetTimer,
  updateCurrentTime,
  // setMatchPhase
} from '../store/timerSlice';
import { sendMessage } from '../utils/broadcast';
import { saveTimerSettings } from '../utils/db';
import buzzerSound from '../assets/sound/buzzer04.mp3';

const TimerControl = () => {
  const dispatch = useDispatch();
  const {
    initialTime = 60,
    currentTime = 60,
    timerState = 'initial',
    matchPhase = 'main',
  } = useSelector((state) => state.timer) || {};

  const [inputMinutes, setInputMinutes] = useState(Math.floor(initialTime / 60));
  const [inputSeconds, setInputSeconds] = useState(initialTime % 60);

  const audioRef = useRef(null);
  const animationRef = useRef(null);
  const startTimeRef = useRef(null);
  const pausedTimeRef = useRef(null);
  const elapsedPausedTimeRef = useRef(0);
  const lastUpdateTimeRef = useRef(0);

  useEffect(() => {
    audioRef.current = new Audio(buzzerSound);
  }, []);

  const updateTimer = useCallback(() => {
    if (timerState === 'running') {
      const now = performance.now();
      if (now - lastUpdateTimeRef.current >= 250) {
        const elapsedSinceStart = (now - startTimeRef.current - elapsedPausedTimeRef.current) / 1000;
        const newCurrentTime = Math.max(0, initialTime - elapsedSinceStart);

        // console.log("now:", now, "elapsedSinceStart:", elapsedSinceStart, "newCurrentTime:", newCurrentTime, "elapsedPausedTime:", elapsedPausedTimeRef.current);

        if (Math.abs(newCurrentTime - currentTime) >= 0.1) {
          dispatch(updateCurrentTime(Math.round(newCurrentTime * 10) / 10));
          if (Math.floor(newCurrentTime) !== Math.floor(currentTime)) {
            sendMessage('updateTimer', { currentTime: newCurrentTime, timerState, matchPhase });
            saveTimerSettings({ currentTime: newCurrentTime, timerState, matchPhase });
          }
        }

        lastUpdateTimeRef.current = now;
        if (newCurrentTime < 1) {
          // ブザーだけ先に鳴らす
          if (audioRef.current) {
            audioRef.current.play().catch((error) => {
              console.log('音声再生が拒否されました:', error);
            });
          }
        }

        if (newCurrentTime <= 0) {
          dispatch(stopTimer());
          sendMessage('updateTimer', { currentTime: 0, timerState: 'finished', matchPhase });
          saveTimerSettings({ currentTime: 0, timerState: 'finished', matchPhase });
          return;
        }
      }
      animationRef.current = requestAnimationFrame(updateTimer);
    }
  }, [dispatch, initialTime, currentTime, timerState, matchPhase]);

  useEffect(() => {
    // console.log("timerState changed:", timerState, "startTimeRef:", startTimeRef.current, "currentTime:", currentTime);
    if (timerState === 'running') {
      if (!startTimeRef.current) {
        startTimeRef.current = performance.now();
      }
      animationRef.current = requestAnimationFrame(updateTimer);
    } else {
      cancelAnimationFrame(animationRef.current);
    }

    return () => cancelAnimationFrame(animationRef.current);
  }, [timerState, updateTimer]);

  const handleTimerControl = useCallback((action) => {
    // console.log("action:", action, "startTimeRef:", startTimeRef.current, "pausedTimeRef:", pausedTimeRef.current, "currentTime:", currentTime, "elapsedPausedTime:", elapsedPausedTimeRef.current);
    let newTimerState;
    const now = performance.now();
    switch (action) {
      case 'start':
        newTimerState = 'running';
        dispatch(startTimer());
        startTimeRef.current = now;
        elapsedPausedTimeRef.current = 0;
        break;
      case 'pause':
        newTimerState = 'paused';
        dispatch(pauseTimer());
        pausedTimeRef.current = now;
        break;
      case 'resume':
        newTimerState = 'running';
        dispatch(resumeTimer());
        if (pausedTimeRef.current !== null) {
          elapsedPausedTimeRef.current += now - pausedTimeRef.current;
        }
        pausedTimeRef.current = null;
        break;
      case 'stop':
        newTimerState = 'finished';
        dispatch(stopTimer());
        startTimeRef.current = null;
        pausedTimeRef.current = null;
        elapsedPausedTimeRef.current = 0;
        break;
      case 'reset':
        newTimerState = 'initial';
        dispatch(resetTimer(initialTime));
        startTimeRef.current = null;
        pausedTimeRef.current = null;
        elapsedPausedTimeRef.current = 0;
        break;
      default:
        return;
    }
    sendMessage('updateTimer', { currentTime, timerState: newTimerState, matchPhase });
    saveTimerSettings({ currentTime, timerState: newTimerState, matchPhase });
  }, [dispatch, initialTime, currentTime, matchPhase]);

  const handleTimeSet = useCallback(() => {
    const newTime = inputMinutes * 60 + inputSeconds;
    dispatch(resetTimer(newTime));
    sendMessage('updateTimer', { currentTime: newTime, timerState: 'initial', matchPhase });
    saveTimerSettings({ initialTime: newTime, currentTime: newTime, timerState: 'initial', matchPhase });
    startTimeRef.current = null;
    elapsedPausedTimeRef.current = 0;
  }, [dispatch, inputMinutes, inputSeconds, matchPhase]);

  useEffect(() => {
    handleTimeSet();
  }, [inputMinutes, inputSeconds, handleTimeSet]);

  // 時間表示のフォーマット関数
  const formatTime = (seconds) => {
    const totalSeconds = Math.floor(seconds); // 秒以下を切り捨て
    const mins = Math.floor(totalSeconds / 60);
    const secs = totalSeconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="p-timerControl">
      <h2 className="p-timeControl__mainTitle">試合時間設定</h2>

      <div className="p-timeControl-wrap">
        <div className="p-timeControl-box">
          <div className="p-timerControl__inputs">
            <input
              type="number"
              value={inputMinutes}
              onChange={(e) => setInputMinutes(Math.max(0, Math.min(59, parseInt(e.target.value) || 0)))}
              min="0"
              max="59"
            />
            <span>分</span>
            <input
              type="number"
              value={inputSeconds}
              onChange={(e) => setInputSeconds(Math.max(0, Math.min(59, parseInt(e.target.value) || 0)))}
              min="0"
              max="59"
            />
            <span>秒</span>
          </div>
          <div className='p-timerControl-preset'>
            <button onClick={() => { setInputMinutes(2); setInputSeconds(0); }}>2分</button>
            <button onClick={() => { setInputMinutes(1); setInputSeconds(30); }}>1分30秒</button>
            <button onClick={() => { setInputMinutes(1); setInputSeconds(0); }}>1分</button>
          </div>

          <div className="p-timeControl-countdown">
            表示時間：
            <span className="p-timerControl__time">{formatTime(currentTime)}</span>
          </div>

          <div className="p-timerControl__actions">
            {/* <button onClick={handleTimeSet}>時間設定</button> */}
            <button className="p-timeControl__reset" onClick={() => handleTimerControl('reset')}>リセット</button>
          </div>
        </div>

        <div className="p-timeControl-box p-timeControl-actions">
          <div className="p-timerControl__actions">
            {timerState === 'initial' && <button onClick={() => handleTimerControl('start')}>スタート</button>}
            {timerState === 'running' && <button onClick={() => handleTimerControl('pause')}>停止</button>}
            {timerState === 'paused' && <button onClick={() => handleTimerControl('resume')}>再開</button>}
            {/* {timerState !== 'initial' && <button onClick={() => handleTimerControl('stop')}>ストップ</button>} */}
          </div>
        </div>
      </div>
      {/* <div className="p-timerControl__matchPhase">
        <button
            onClick={() => dispatch(setMatchPhase('main'))}
            className={`p-timerControl__matchPhaseButton ${matchPhase === 'main' ? 'p-timerControl__matchPhaseButton--active' : ''}`}
          >
            本戦
          </button>
          <button
            onClick={() => dispatch(setMatchPhase('extension'))}
            className={`p-timerControl__matchPhaseButton ${matchPhase === 'extension' ? 'p-timerControl__matchPhaseButton--active' : ''}`}
          >
            延長
          </button>
          <button
            onClick={() => dispatch(setMatchPhase('reextension'))}
            className={`p-timerControl__matchPhaseButton ${matchPhase === 'reextension' ? 'p-timerControl__matchPhaseButton--active' : ''}`}
          >
            再延長
          </button>
      </div> */}
    </div>
  );
};

export default React.memo(TimerControl);
