import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MatchState {
  court: string;
  matchNumber: number;
  category: string;
  matchPhase: 'main' | 'extension' | 'secondExtension';
}

const initialState: MatchState = {
  court: 'A',
  matchNumber: 1,
  matchPhase: 'main',
  category: '幼年男女混合初心',
};

export const generalDisplayMatchSlice = createSlice({
  name: 'match',
  initialState,
  reducers: {
    updateMatchInfo: (state, action: PayloadAction<Partial<MatchState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateMatchInfo } = generalDisplayMatchSlice.actions;
export default generalDisplayMatchSlice.reducer;
