import React from 'react';
import { useSelector } from 'react-redux';

const Timer = () => {
  const { currentTime } = useSelector((state) => state.timer);
  // const { matchPhase  } = useSelector((state) => state.pahse);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="p-timer">
      <span className="p-timer-time">{formatTime(currentTime)}</span>
      {/* <div className={`p-timer-phase p-timer-phase--${matchPhase}`}>
        <span></span>
        <span></span>
        <span></span>
      </div> */}
    </div>
  );
};

export default React.memo(Timer);
