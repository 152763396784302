import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateMatchInfoAsync } from '../store/matchSlice';

const MatchInput = () => {
    const dispatch = useDispatch();
    const { court, matchNumber, category } = useSelector(state => state.match);
    const [localCourt, setLocalCourt] = useState(court);
    const [localMatchNumber, setLocalMatchNumber] = useState(matchNumber);
    const [localCategory, setLocalCategory] = useState(category);

    useEffect(() => {
        setLocalCourt(court);
        setLocalMatchNumber(matchNumber);
        setLocalCategory(category);
    }, [court, matchNumber, category]);

    const handleCourtChange = (e) => {
        const value = e.target.value.toUpperCase().replace(/[^A-Z]/g, '');
        setLocalCourt(value);
    };

    const handleMatchNumberChange = (change) => {
        setLocalMatchNumber(prev => Math.max(1, Number(prev) + change));
    };

    const handleMatchNumberInput = (e) => {
        const value = e.target.value;
        if (value === '' || /^[1-9]\d*$/.test(value)) {
            setLocalMatchNumber(value === '' ? '' : Number(value));
        }
    };

    const handleMatchNumberBlur = () => {
        if (localMatchNumber === '' || Number(localMatchNumber) < 1) {
            setLocalMatchNumber(1);
        }
    };

    const handleCategoryChange = (e) => {
        setLocalCategory(e.target.value);
    };

    const handleConfirm = () => {
        dispatch(updateMatchInfoAsync({
            court: localCourt,
            matchNumber: Number(localMatchNumber),
            category: localCategory
        }));
    };

    return (
        <div className="p-matchInput">
            <h2 className="p-matchInput__mainTitle">試合番号&カテゴリ設定</h2>

            <div className="p-matchInput__wrap">
                <div className="p-matchInput__box">
                    <h3 className="p-matchInput__title">コート名</h3>
                    <input
                        type="text"
                        value={localCourt}
                        onChange={handleCourtChange}
                        className="p-matchInput__input"
                        maxLength={1}
                    />
                </div>

                <div className="p-matchInput__box">
                    <h3 className="p-matchInput__title">試合番号</h3>

                    <div className="p-matchInput-setting">
                        <input
                            type="number"
                            value={localMatchNumber}
                            onChange={handleMatchNumberInput}
                            onBlur={handleMatchNumberBlur}
                            className="p-matchInput-setting__number"
                        />

                        <div className="p-matchInput-setting__buttons">
                            <button onClick={() => handleMatchNumberChange(1)}>▲</button>
                            <button onClick={() => handleMatchNumberChange(-1)}>▼</button>
                        </div>
                    </div>
                </div>

                <div className="p-matchInput__box">
                    <h3 className="p-matchInput__title">カテゴリ</h3>
                    <select
                        value={localCategory}
                        onChange={handleCategoryChange}
                        className="p-matchInput__select"
                    >
<option value="＜初心＞年少・年中<br />（男女混合）">＜初心＞年少・年中（男女混合）</option>
<option value="＜初心＞幼年<br>（男女混合）">＜初心＞幼年（男女混合）</option>
<option value="＜初心＞小学１・２年<br>（男女混合）">＜初心＞小学１・２年（男女混合）</option>
<option value="＜初心＞小学４年<br>（男女混合）">＜初心＞小学４年（男女混合）</option>
<option value="＜初心＞<br>小学５年男子">＜初心＞小学５年男子</option>
<option value="＜初級＞<br>小学４年女子">＜初級＞小学４年女子</option>
<option value="＜初中級＞幼年<br>（男女混合）">＜初中級＞幼年（男女混合）</option>
<option value="＜初級＞小学1年<br>（男女混合）">＜初級＞小学1年（男女混合）</option>
<option value="＜初級＞<br>小学２年男子">＜初級＞小学２年男子</option>
<option value="＜初級＞<br>小学２年女子">＜初級＞小学２年女子</option>
<option value="＜中級＞<br>小学２年女子">＜中級＞小学２年女子</option>
<option value="＜初級＞<br>小学３年男子">＜初級＞小学３年男子</option>
<option value="＜初級＞<br>小学４年男子">＜初級＞小学４年男子</option>
<option value="＜中級＞<br>小学４年男子">＜中級＞小学４年男子</option>
<option value="＜初級＞<br>小学５年男子">＜初級＞小学５年男子</option>
<option value="＜初中級＞<br>小学５年女子">＜初中級＞小学５年女子</option>
<option value="＜初中級＞小学６年・<br>中学１年男子">＜初中級＞小学６年・中学１年男子</option>
<option value="＜中級＞<br>中学２・３年男子">＜中級＞中学２・３年男子</option>
<option value="＜選抜＞<br>小学２年男子">＜選抜＞小学２年男子</option>
<option value="＜選抜＞小学3年男子<br>（―29kg)">＜選抜＞小学3年男子（―29kg)</option>
<option value="＜選抜＞小学3年男子<br>（＋29kg)">＜選抜＞小学3年男子（＋29kg)</option>
<option value="＜選抜＞小学４年男子<br>（－32㎏）">＜選抜＞小学４年男子（－32㎏）</option>
<option value="＜選抜＞小学４年男子<br>（＋32㎏）">＜選抜＞小学４年男子（＋32㎏）</option>
<option value="＜選抜＞<br>小学２年女子">＜選抜＞小学２年女子</option>
<option value="＜選抜＞<br>小学３年女子">＜選抜＞小学３年女子</option>
<option value="＜選抜＞小学４年女子<br>（−32kg）">＜選抜＞小学４年女子（−32kg）</option>
<option value="＜選抜＞小学５年男子<br>（−36kg）">＜選抜＞小学５年男子（−36kg）</option>
<option value="＜選抜＞小学６年男子<br>（−42kg)">＜選抜＞小学６年男子（−42kg)</option>
<option value="＜選抜＞小学６年男子<br>（＋42kg)">＜選抜＞小学６年男子（＋42kg)</option>
<option value="＜選抜＞中学１年男子<br>（－50kg)">＜選抜＞中学１年男子（－50kg)</option>
<option value="＜選抜＞中学２・３年<br>男子(−55kg)">＜選抜＞中学２・３年男子(−55kg)</option>
<option value="＜選抜＞中学２・３年<br>男子(＋55kg)">＜選抜＞中学２・３年男子(＋55kg)</option>
<option value="＜選抜＞高校生男子<br>（−65kg)">＜選抜＞高校生男子（−65kg)</option>
<option value="＜上級＞幼年・<br>小学1年男子">＜上級＞幼年・小学1年男子</option>
<option value="＜上級＞<br>小学２年男子">＜上級＞小学２年男子</option>
<option value="＜上級＞<br>小学３年男子">＜上級＞小学３年男子</option>
<option value="＜上級＞<br>小学４年男子">＜上級＞小学４年男子</option>
<option value="＜上級＞<br>小学５年男子">＜上級＞小学５年男子</option>
<option value="＜上級＞<br>小学３年女子">＜上級＞小学３年女子</option>
<option value="＜上級＞小学４年女子<br>（＋32kg）">＜上級＞小学４年女子（＋32kg）</option>
<option value="＜上級＞小学５年女子<br>（−36kg）">＜上級＞小学５年女子（−36kg）</option>
<option value="＜上級＞小学５年女子<br>（＋36kg）">＜上級＞小学５年女子（＋36kg）</option>
<option value="＜上級＞小学６年男子<br>（−42kg)">＜上級＞小学６年男子（−42kg)</option>
<option value="＜上級＞小学６年男子<br>（＋42kg)">＜上級＞小学６年男子（＋42kg)</option>
<option value="＜上級＞小学6年・<br>中学１年女子">＜上級＞小学6年・中学１年女子</option>
<option value="＜上級＞中学１年男子<br>（―50kg)">＜上級＞中学１年男子（―50kg)</option>
<option value="＜上級＞中学１年男子<br>（＋50kg)">＜上級＞中学１年男子（＋50kg)</option>
<option value="＜上級＞<br>中学２・３年男子">＜上級＞中学２・３年男子</option>
<option value="＜エキシビションマッチ＞<br>高校男子（－65㎏）上級">＜エキシビションマッチ＞高校男子（－65㎏）上級</option>
<option value="＜初級＞<br>一般男子(―70kg)">＜初級＞一般男子(―70kg)</option>
<option value="＜初級＞<br>一般男子(＋70kg)">＜初級＞一般男子(＋70kg)</option>
<option value="＜初級＞<br>一般女子">＜初級＞一般女子</option>
<option value="壮年男子">壮年男子</option>
<option value="＜上級＞<br>高校・一般女子">＜上級＞高校・一般女子</option>
<option value="＜上級＞<br>一般男子">＜上級＞一般男子</option>
                    </select>
                </div>
            </div>
            <button className="p-matchInput__send" onClick={handleConfirm}>QR設定送信</button>
        </div>
    );
};

export default MatchInput;
