import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sendMessage } from '../utils/broadcast';

type MatchPhase = 'main' | 'extension' | 'reextension';

interface PhaseState {
  matchPhase: MatchPhase;
}

const initialState: PhaseState = {
  matchPhase: 'main',
};

export const phaseSlice = createSlice({
  name: 'phase',
  initialState,
  reducers: {
    setMatchPhase: (state, action: PayloadAction<MatchPhase>) => {
      state.matchPhase = action.payload;
      sendMessage('updateMatchPhase', { matchPhase: action.payload });
    },
  },
});

export const { setMatchPhase } = phaseSlice.actions;
export default phaseSlice.reducer;
